/**
 * Style the Header Scroll
 *
 * Styles the header to have a white background with
 * dark text upon vertical scroll.
 */

.bg-header-scroll {
  background: white;
  color: black;
}

.bg-header-scroll .navbar-link,
.bg-header-scroll .fas {
  color: black;
}

/**
 * Fix Z-Index Overlay Issues
 *
 * Fixes issues caused by z-index overlays.
 * - Background none prevents the background from
 *   being overlayed above the first nav item
 * - Right 0 pins the header/close button to the right
 * - Width 50% keeps it from overlaying the first nav item
 *
 * We probably don't need to turn off the background since we
 * have a width set to 50%; however, it's a good fallback
 * for cases where a nav item may extend past the halfway point.
 */
.hide-header-scroll-bg #bg-header-scroll {
  background: none !important;
  right: 0;
  width: 50%;
}
