.has-float-label {
    display: block;
    position: relative;
}
.has-float-label input, .has-float-label select, .has-float-label textarea, .redactor-box {
    padding-left: 10px;
    padding-top: 10px;
    border: 1px solid #d8d8d8 !important;
    /*box-shadow: inset 0px 1px 1px 0px #d8d8d8;*/
    border-radius: 4px;
    line-height: 3;
    width: 100%;
    background-color: #fff;
}
.has-float-label input:focus, .has-float-label textarea:focus, .redactor-focus.redactor-styles-on, .redactor-focus:focus.redactor-styles-on {
    border: 0px solid #c7cbd1 !important;
}
.has-float-label input::placeholder, .has-float-label textarea::placeholder {
    opacity: 1;
    transition: all .5s;
}
.has-float-label input:placeholder-shown:not(:focus)+*, .has-float-label textarea:placeholder-shown:not(:focus)+* {
    font-size: 20px;
    line-height: 1;
    color: #a4a5a7;
    top: 50%;
    transform: translate(0, -50%);
}
.has-float-label label, .has-float-label>span {
    cursor: text;
    font-size: 12px;
    line-height: 1;
    position: absolute;
    top: 10px;
    left: 12px;
    opacity: 1;
    transition: all .3s;
    z-index: 3;
    color: #a4a5a7;
}
.has-float-label label::after, .has-float-label>span::after {
    content: " ";
    display: block;
    height: 2px;
    position: absolute;
    top: 50%;
    left: -.2em;
    right: -.2em;
    z-index: -1;
}
.has-float-label textarea {
    padding-top: 30px;
    line-height: 1.3em;
}
.redactor-box {
    padding: 0px !important;
    padding-top: 30px !important;
    /*box-shadow: none !important;*/
    border: 0px !important;
    box-shadow: 0px 1px 2px 0px #ccc !important;
}
.redactor-toolbar {
    box-shadow: 0px 1px 2px 0px #ccc !important;
}
.redactor-toolbar-box {
    top: 5px !important;
}
/* chrome yellow deal */

/*@-webkit-keyframes autofill {
    to {
        color: #000;
        background: #fff;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

*/

.btn-disabled {
    background-color: grey !important;
    pointer-events: none;
}
input.search-input {
    box-sizing: border-box;
    border-radius: 4px;
    background-color: white;
    background-image: url(/images/search_icon.svg);
    background-position: 10px 12px;
    background-size: 24px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
}
