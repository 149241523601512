/**
 * Textrea Expansion
 *
 * Creates a text area that auto-expands to fit
 * its content.
 *
 * Note that the class name has been changed from
 * .expandingArea in the reference article to
 * .expanding-textarea for better clarity.
 *
 * CSS properties have been alphabetized, and
 * certain properties have been eliminated from
 * the reference article cited below.
 *
 * @see http://alistapart.com/article/expanding-text-areas-made-elegant
 */

.expanding-textarea {
    position: relative;
}

.expanding-textarea textarea,
.expanding-textarea pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.expanding-textarea textarea {
    height: 100px;
    width: 100%;
}

.expanding-textarea.active textarea {
    height: 100%;
    overflow: hidden;
    position: absolute;
        top: 0;
        left: 0;
    resize: none;
}

.expanding-textarea pre {
    display: none;
}

.expanding-textarea.active pre {
    display: block;
    visibility: hidden;
}
