/*.image-preview-single {
	width: 100%;
	height: auto;
	border: 2px solid #666;
}*/

.uploadcare--widget__button:hover {
	background-color: #000;
}
.uploadcare--widget__button {
	cursor: pointer !important;
	margin-top: 4px;
	padding: 15px 30px !important;
	width: 100% !important;
	border-radius: 4px !important;
	background-color: #000 !important;
}
/*.uploadcare--widget__text {
	display: none;
}
.image-preview-single {
	padding: 10px 0;
}
.image-preview-single>img {
	width: 200px;
	height: 200px;
	vertical-align: top;
	display: inline-block;
}
*/
