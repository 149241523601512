/**
 * Questions
 *
 * Styles the question cards and other items related to
 * the Questions section.
 */

.question-card:hover > .question-title {
    color: white;
    background: #AF2074;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08);
}
