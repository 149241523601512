/**
 * Transitions
 *
 * Creates utility classes for custom animated transitions
 * and other stateful effects.
 */

.transitions {
    transition: all .1s;
}

.hover\:scale-up:hover {
    transform: scale(1.15);
     transition: all .1s;
    transform-origin: bottom left;
    color: #5c3f9a;
}

.fade-in {
    opacity: 0;

    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}