/**
 * HTML/CSS Global Nav Dropdowns
 */

.bg-light-filler {
  /* background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0 ) 100%);*/
  z-index: 1000;
  background-blend-mode: overlay;
}

.z-65001 {
  z-index: 65001;
}

.navbar {
  @apply list-none text-xl mx-auto;
}
@screen lg {
  .navbar {
    @apply flex flex-wrap items-end mr-10;
  }
}
.navbar-item {
  @apply font-black cursor-pointer my-2;
}
@screen lg {
  .navbar-item {
    @apply my-0;
  }
}
@screen xl {
  .navbar-item {
  }
}
.navbar-link {
  @apply flex justify-between no-underline py-2 pt-4 text-black;
}
.navbar-link.has-dropdown:hover {
  @apply rounded-t;
}
.navbar-link:hover {
  @apply text-black;
}
@screen lg {
  .navbar-link {
    @apply inline-block px-8;
  }
  .navbar-link.has-dropdown:hover {
    @apply bg-black-90;
  }
}
@screen xl {
  .navbar-link {
    @apply px-8;
  }
}
/* Hover/focus states to trigger dropdowns */

.navbar-item:hover > .has-dropdown {
  @apply text-white;
}
.navbar-dropdown {
  display: none;
}
.navbar-item:hover .navbar-dropdown,
.navbar-item:focus-within .navbar-dropdown {
  display: block;
}
@screen lg {
  .navbar-item:hover > .has-dropdown {
    @apply bg-black-90;
  }
}
/* Dropdowns */

.navbar-dropdown {
  @apply mb-6 text-white;
}
@screen lg {
  .navbar-dropdown {
    @apply bg-black-90 shadow-lg;
  }
}
/* This is the container for the dropdown cards. */

.navbar-dropdown-container {
  @apply justify-center my-0 mx-auto py-2 pl-0;
  list-style: none;
}
@screen lg {
  .navbar-dropdown-container {
    @apply p-8;
  }
}
@screen lg {
  .navbar-dropdown-container {
    @apply flex flex-wrap;
  }
}
/* Navbar Dropdown Cards */

.navbar-dropdown-card {
  @apply mb-4;
}
@screen lg {
  .navbar-dropdown-card {
    @apply text-center px-4 w-1/5;
  }
  .navbar-dropdown-card:hover {
    transition: 0.25s ease-in-out;
    transform: scale(1.1);
  }
}
@screen lg {
  .navbar-dropdown-card {
    @apply mb-0;
  }
}
/* Navbar Dropdown Links */

.bg-sp{
  background-size: 100%;
  background-position: center center;
}

.navbar-dropdown-link-img {
  @apply hidden no-underline;
}
@screen lg {
  .navbar-dropdown-link-img {
    @apply inline w-full;
  }
}
.navbar-dropdown-link {
  @apply no-underline text-white;
}
.navbar-dropdown-link:hover {
  @apply text-white;
}
/* Navbar Dropdown Content */

.navbar-dropdown-img {
  @apply mb-2;
}
.navbar-dropdown-headline {
  @apply leading-normal text-base;
}
.flatpickr-input[readonly] {
  padding-bottom: 0px;
}
@screen lg {
  @keyframes scale {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }
  .navbar-item .navbar-dropdown > .navbar-dropdown-container {
    animation-duration: 0.5s;
    animation-name: scale;
  }
}
