@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* purgecss start ignore */

@import "article-styles.css";
@import "editor-content.css";
@import "forms.css";
@import "checkboxes.css";
@import "textarea-expansion.css";
@import "questions.css";
@import "transitions.css";
@import "uploadcare.css";
@import "navbar.css";
@import "navbar-slideout.css";
@import "header-scroll.css";
@import "tooltip.css";
@import "google-maps.css";
@import "embedded.css";
@import "fontawesome.css";

/* Alpine hide */
[x-cloak] {
  display: none;
}

html {
  font-family: avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

/* TYPE */

h1 {
  @apply font-bold text-5xl;
}

h2 {
  @apply font-bold text-3xl;
}

input::-moz-placeholder,
input:-ms-input-placeholder,
input::-webkit-input-placeholder,
input:-moz-placeholder {
  color: #2c2d26;
  opacity: 1;
}
textarea::-webkit-input-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea:-moz-placeholder {
  color: #2c2d26;
  opacity: 1;
}
.bg-smoke {
  background-color: rgba(0, 0, 0, 0.3);
}

.container {
  max-width: 1200px !important;
}

/**
 * Adds border styles to Faces image, since Tailwind does not apply hover styles to borders.
 */

.bo_face:hover > div > img {
  border: 12px solid black;
}
.bo_face-content {
  visibility: hidden;
}
.bo_face:hover .bo_face-content {
  visibility: visible;
}
/* Style Guide Specific Styles */

.sg_content {
  @apply overflow-scroll py-8 px-6;
}
.sg_sidebar {
  @apply bg-black overflow-scroll py-8 px-6 text-white;
}
.sg_nav-link {
  @apply block border-b-2 border-white font-black no-underline py-3 text-white;
}
.router-link-active,
.sg_nav-link:hover {
  @apply border-red-500 text-white;
}
/* ADMIN NAV */

.bo-nav-link {
  @apply no-underline text-white block h-full w-full border-b border-indigo-800 px-8 py-4;
}
.card {
  @apply bg-white p-6 text-black shadow;
}
.btn {
  @apply cursor-pointer bg-black inline-block no-underline py-2 px-4 text-center text-white text-xl rounded;
}
.btn:hover {
  @apply bg-purple;
}
.btn-secondary {
  @apply bg-red-500;
}
.btn-tertiary {
  @apply bg-purple;
}
.btn:hover,
.btn-secondary:hover {
  @apply bg-purple;
}
.input {
  @apply bg-white p-3 rounded text-black border border-gray-300;
}
.search {
  background-image: url("/images/search_icon.svg");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-size: 20px;
  background-position: 7px 12px;
}
.badge {
  @apply bg-black inline-block no-underline py-1 px-2 text-center text-white text-sm;
}
/**
 * Custom Backgrounds
 */

.bg-articles {
  background-color: black;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-authors {
  background-color: black;
  background-image: url("/images/bg-authors.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-black-40 {
  background-color: rgba(0, 0, 0, 0.4);
}
.bg-circles {
  background-image: url("/images/bg-circles.png");
  background-position: 75% 360%;
  background-repeat: no-repeat;
}
.bg-faces {
  background-color: black;
  background-image: url("/images/bg-faces.png");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-famous-bi-people {
  background-color: black;
  background-image: url("/images/bg-famous-bi-people.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-famous-bi-person {
  background-color: black;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-feedback {
  background-color: black;
  background-image: url("/images/bg-famous-bi-people.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-hero {
  background-color: black;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-login {
  background-color: black;
  background-image: url("/images/bg-login.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-page {
  background-color: black;
  background-image: url("/images/bg-pages.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overflow-hidden {
  overflow: hidden !important;
}

/**
 * Articles Page Feed
 */

.result-0 {
  margin-top: -64px;
}
@screen lg {
  .result-0 {
    width: 50% !important;
  }
}
/**
 * Pagination Front End Styles
 */

.active-page .page-link {
  @apply bg-black text-white;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.nav-link-admin {
  @apply flex-nowrap pl-2 border-l-4 border-transparent text-black no-underline py-2;
  transition: 0.05s ease-in;
}
.nav-link-admin:hover {
  @apply bg-gray-200 text-black;
}
.nav-link-admin.active {
  @apply bg-gray-400 text-black border-black;
}
/**
 * Prompts - Join
 *
 * This creates a half-circle pop-out on the login
 * page to prompt the user to create an account
 * if they do not currently have one.
 *
 * The parent .prompt-join should always have a negative
 * margin of half its width, as well as equal height/width
 * to ensure a perfect circle. Care should be taken to fit
 * the lettering within the assigned size.
 *
 * Any element to the left .prompt-join should include
 * a z-index so that it overlays the left half of the
 * .prompt-join circle. Tailwind provides z-index classes.
 *
 * Prompt content should be included within
 * .prompt-join-content 
 *
 */

.prompt-join {
  @apply p-8;
}
@screen md {
  .prompt-join {
    border-radius: 50%;
    height: 420px;
    margin-left: -210px;
    padding: 0;
    width: 420px;
  }
  .prompt-join-content {
    padding-left: 222px;
  }
}

/* some other stuff*/
.image-preview-single {
  position: relative;
}
.uploadcare--widget {
  position: relative;
}
.uploadcare--widget__text {
  /*display: none;*/
}
.uploadcare--link.uploadcare--widget__file-name {
  /*background-color: red;*/
  margin: 0px;
  padding: 4px;
  /*position: absolute;*/
  /*bottom:0px;*/
  /*left:0px;*/
}

/* Glossary */
.glossary-links a {
  text-decoration: none;
  color: #000;
  border-bottom: 1px solid #ccc;
  outline: none;
  cursor: help;
}

/* SLIDESHOW */

.flickity-prev-next-button {
  width: 100px;
  height: 100px;
  background: transparent !important;
}
/* arrow color */
.flickity-prev-next-button .arrow {
  fill: white;
}
.flickity-prev-next-button.no-svg {
  color: white;
}
.flickity-prev-next-button:hover {
  background: transparent;
}
/* hide disabled button */
.flickity-prev-next-button:disabled {
  display: none;
}

/* Type Contrast Fix */
.font-shadow {
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.62);
}

.flickity-page-dots {
  bottom: 10px;
}
.carousel-cell {
  width: 100%;
}

/* position dots in carousel */
.flickity-page-dots {
  bottom: 30px;
}
/* white circles */
.flickity-page-dots .dot {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: transparent;
  border: 2px solid white;
}
/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  background: white;
}

#article img {
  display: inline;
}

.bg-gradient-1,
.bg-gradient-2,
.bg-gradient-3,
.bg-gradient-4,
.bg-gradient-5,
.bg-gradient-6,
.bg-gradient-7 {
  color: #fff;
}

.bg-gradient-1 {
  background: #f01e4c;
}
.bg-gradient-2 {
  background: #d92357;
}
.bg-gradient-3 {
  background: #c02963;
}
.bg-gradient-4 {
  background: #a52f6f;
}
.bg-gradient-5 {
  background: #8b357b;
}
.bg-gradient-6 {
  background: #723b87;
}
.bg-gradient-7 {
  background: #5d4091;
}

/* Text Shadow */
.text-shadow {
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
}

/* Group Hover Not Screen Reader Only */
.group:hover .group-hover\:not-sr-only {
  position: absolute;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

/* Faces Grid */
.faces-grid {
  @apply gap-4;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns by default for mobile */
  grid-template-rows: repeat(
    15,
    auto
  ); /* Adjusted for all 31 cells on mobile */
  grid-template-areas:
    "W01 W01 W01"
    "W01 W01 W01"
    "X02 X03 X04"
    "X05 X06 X07"
    "X08 X09 X10"
    "X11 X12 X13"
    "W15 W15 W15"
    "W15 W15 W15"
    "X14 X16 X17"
    "X18 X19 X21"
    "W20 W20 W20"
    "W20 W20 W20"
    "X22 X23 X24"
    "X25 X26 X27"
    "X28 X29 X30";

  @screen md {
    grid-template-columns: repeat(5, 1fr); /* 5 columns on md and above */
    grid-template-rows: repeat(8, auto); /* 8 rows on md and above */
    grid-template-areas:
      "W01 W01 X02 X03 X04"
      "W01 W01 X05 X06 X07"
      "X08 X09 X10 X11 X12"
      "X13 X14 W15 W15 X16"
      "X17 X18 W15 W15 X19"
      "W20 W20 X21 X22 X23"
      "W20 W20 X24 X25 X26"
      "X27 X28 X29 X30 X31";
  }
}

/* purgecss end ignore */
