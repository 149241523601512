/**
 * Custom Article Styles
 *
 * Applies custom CSS to articles created using Redactor
 * to account for dynamic content where standard Tailwind
 * classes cannot be applied by the user/author.
 */

#article h2,
#article h3 {
  @apply font-bold;
  margin-top: 20px;
  margin-bottom: 5px;
}

#article h3 {
  @apply font-bold text-2xl;
}

#article h4 {
  @apply font-bold text-xl;
}

#article h5 {
  @apply font-bold text-lg;
}

#article h6 {
  @apply italic;
}

#article blockquote {
  color: #505050;
  /*border-left: .25rem solid #AF2074;*/
  font-style: italic;
  padding-left: 2rem;
}
#article blockquote:not(:last-child),
#article h1:not(:last-child),
#article h2:not(:last-child),
#article h3:not(:last-child),
#article h4:not(:last-child),
#article h5:not(:last-child),
#article h6:not(:last-child),
#article ol:not(:last-child),
#article pre:not(:last-child),
#article p:not(:last-child),
#article ul:not(:last-child) {
  margin-bottom: 1rem;
}
#article figure {
  /*this can not be 100 as images need to float*/
  /*width: 100%;*/
  /*display: table; */
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  margin-bottom: 1rem;
}

#article figcaption {
  /*text-align: left;*/
  font-style: italic;
  margin-top: -6px;
  padding-left: 0px;
  font-size: 11px;
  margin-bottom: 10px;
  color: #303030;
  width: 100%;
  /*display: table-caption;*/
  caption-side: bottom;
}
#article hr {
  border-width: 1px;
}
#article img {
  display: inline;
  height: auto !important;
  margin-bottom: 1rem;
}
/*#article iframe {
  width: 100% !important;
}*/

/*.flex.flex-row.justify-center iframe {
  width: 100%;
  height: auto;
}*/

/*.video-responsive, .flex.flex-row.justify-center{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.video-responsive iframe, .flex.flex-row.justify-center iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
*/

/* tweet embed */
.twitter-tweet {
  padding-left: 10px;
  margin: 0px auto;
}

#article a {
  text-decoration: none;
  color: #000;
  border-bottom: 1px solid #ccc;
  outline: none;
}
#article a:hover {
  border-bottom: 1px solid black;
}

#article .alignleft {
  float: left;
  padding: 20px 20px 20px 0px;
}

#article .redactor-citation-item {
  font-size: 10px;
  text-decoration: none !important;
  border-bottom: 0px solid #ccc;
}

#article .youtube-video-wrapper {
  margin-bottom: 1rem;
}
