.editor-content {
	/*background: red;*/
}
.editor-content img {
	width: 100%;
}
.editor-content p {
	margin: 10px 0px;
}
.editor-content a {
	font-weight: bold;
	color: #000;
}
/* Redactor Style Changes */

.redactor-toolbar {
	background-color: white !important;
}