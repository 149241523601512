.instagram-media, .twitter-tweet-rendered {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100%;
    max-width: 450px;
    min-width: 50px !important;
}
/* 
.video-container > figure,
#article .youtube-video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

#article > div.flex-row.justify-center > figure {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    width: 100%;
}

.video-container > figure iframe,
#article .youtube-video-wrapper iframe,
#article > div.flex-row.justify-center > figure > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.youtube-video-wrapper {
    margin-bottom: 30px;
} */

