/**
 * Navbar Slideout
 *
 * Style the mobile slideout nav menu.
 */

/**
  * Hide the Hamburger and Close
  *
  * Custom class to hide the menu toggles, for greater
  * specificity over the Font Awesome display properties.
  */
.icon-hide {
  display: none !important;
}

/**
 * Default State
 *
 * Position the slideut nav to the right, off screen.
 * Make sure that overflow hidden is applied to the parent,
 * otherwise the nav will show on horizontal scroll.
 */
.nav-slideout {
  transform: translateX(100%);
  z-index: 5000;
}

/**
 * Shame
 *
 * This is applied to the fixed-positioned logo and nav toggles
 * to keep them above other elements, including the popout (so the
 * nav close icon is still visible.
 */
.z-5001 {
  z-index: 5001;
}

/**
 * Visible State
 *
 * Position the nav on screen when the is-open state is
 * active; is-open is toggled by JavaScript whenever the
 * hamburger is clicked.
 */
.nav-slideout.is-open {
  transform: translateX(0);
}

/* Create the sliding animation on transform changes. */
.nav-slideout-toggle-area {
  transition: opacity 0.5s;
  transition: transform 0.5s;
}

/**
 * Use Vanilla CSS
 *
 * No need to use Tailwind classes for the slideout nav;
 * the layout is simple enough that the following CSS is
 * more easily edited/applied.
 */
.slideout-nav h2 {
  color: #606060; /* @ToDo, replace with Bi.org Grey */
  font-size: 1rem;
  font-weight: 300;
  margin: 2rem 0 0.5rem;
}

.slideout-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.slideout-nav a {
  color: black;
  display: block;
  font-weight: 900;
  padding: 0.4rem 0;
  text-decoration: none;
  text-transform: uppercase;
}
